


























import { defineComponent, computed } from '@vue/composition-api';
import forEach from 'lodash/forEach';
import pickBy from 'lodash/pickBy';
import { VueFunnelGraph } from 'vue-funnel-graph-js';
import { $DYNAMIC_REPORT_CONFIGS } from '../composables/config';

export default defineComponent({
  components: {
    VueFunnelGraph,
  },
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup(props, context) {
    const { $i18n } = context.root;
    const apiData = props.data;

    const statementData = apiData.funnel;
    const dataSet = {};
    const dataLabels = {
      x: {},
      y: {},
    };

    Object.keys(statementData).forEach(item => {
      Object.assign(dataLabels.x, { [item]: item });
    });

    const dataSeries = [];
    dataSeries.push(statementData.total_income.value < 0 ? 0 : statementData.total_income.value);
    dataSeries.push(statementData.gross_profit.value < 0 ? 0 : statementData.gross_profit.value);
    dataSeries.push(statementData.ebit.value < 0 ? 0 : statementData.ebit.value);
    dataSeries.push(statementData.ebt.value < 0 ? 0 : statementData.ebt.value);
    dataSeries.push(statementData.net_profit.value < 0 ? 0 : statementData.net_profit.value);

    const dataCategory = [];
    dataCategory.push(`${$i18n.t('total_income') || 'total_income'} (${statementData.total_income.percentage < 0 ? 0 : statementData.total_income.percentage}%)`);
    dataCategory.push(`${$i18n.t('gross_profit') || 'gross_profit'} (${statementData.gross_profit.percentage < 0 ? 0 : statementData.gross_profit.percentage}%)`);
    dataCategory.push(`${$i18n.t('ebit') || 'ebit'} (${statementData.ebit.percentage < 0 ? 0 : statementData.ebit.percentage}%)`);
    dataCategory.push(`${$i18n.t('ebt') || 'ebt'} (${statementData.ebt.percentage < 0 ? 0 : statementData.ebt.percentage}%)`);
    dataCategory.push(`${$i18n.t('net_profit') || 'net_profit'} (${statementData.net_profit.percentage < 0 ? 0 : statementData.net_profit.percentage}%)`);

    const chartConfigs = {
      labels: dataCategory,
      values: dataSeries,
      colors: ['#18ddb6', '#0dad8d'],
      direction: 'vertical',
      gradientDirection: 'vertical',
      height: 500,
      width: 300,
    };

    return {
      title: `กำไรขาดทุนสุทธิ (Margin Funnel) ปี ${apiData.max_fiscal_year}`,
      chartConfigs,
    };
  },
});
